import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import iconComputer from '../components/svgs/IconComputer.vue';
import iconEnvelope from '../components/svgs/IconEnvelope.vue';
import iconPercentage from '../components/svgs/IconPercentage.vue';
import iconCalculator from '../components/svgs/iconCalculator.vue';
import iconReview from '../components/svgs/iconReview.vue';
import iconConsider from '../components/svgs/iconConsider.vue';
import iconCheck from '../components/svgs/iconCheck.vue';
Vue.use(Vuetify);

const iconsStonewood = {
	iconComputer: {
		component: iconComputer,
	},
	iconEnvelope: {
		component: iconEnvelope,
	},
	iconPercentage: {
		component: iconPercentage,
	},
	iconCalculator: {
		component: iconCalculator,
	},
	iconReview: {
		component: iconReview,
	},
	iconConsider: {
		component: iconConsider,
	},
	iconCheck: {
		component: iconCheck,
	},
}
export default new Vuetify({
    theme: {
        themes: {
                light: {
                    primary: '#49688B',
                    secondary: '#2E4D6F',
                    anchor: '#3F679B',
                    accent: '#6784AC',
                    error: '#C12327',
                    info: '#9CB6DC',
                    success: '#40815B',
                    warning: '#FFC600',

					sw_safron: '#F1C233', //Midnight Blue
					'sw_safron-lighten-5':  '#FCF3D5',
					'sw_safron-lighten-4':  '#FAE9B5',
					'sw_safron-lighten-3':  '#F8DF94',
					'sw_safron-lighten-2':  '#F5D674',
					'sw_safron-lighten-1':  '#F3CC53',
					'sw_safron-darken-1':  '#D7AC2A',
					'sw_safron-darken-2':  '#BD9622',
					'sw_safron-darken-3':  '#A38119',
					'sw_safron-darken-4':  '#896B11',

					sw_bgBlue: '#071D5E',
					sw_bgLightBlue: '#0B409B',
					sw_bgDarkBlue: '#011545',
					sw_btnBlue: '#0A51C2',
					sw_textBlue: "#05264D",
					sw_textDarkBlue: "#073763",

					sw_oxfordBlue: '#0d2547',

                    sw_primary: '#004976',
                    sw_secondary: '#02C6C1',
                    sw_complementary: '#005381',
                    sw_orange: '#FAC642',
                    
                    sw_lightgray: '#DFE1E2',
                    sw_darkgray: '#808285',
                    sw_darkergray: '#444444',

					sw_white: '#ffffff',
                    sw_black: '#000000',
                },
            },
        },
	icons: {
			values: iconsStonewood,
		}
});