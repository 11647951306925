<template>
    <v-card
        class="mx-auto my-4 borderThick fill-height"
        elevation="6"
        outlined
		style="width: 100%;"
        :class=" isCalculated ? 'sw_bgDarkBlue' : 'sw_bgLightBlue'"
    >
            <v-card-title v-if="isCalculated" class="white--text d-flex justify-center ">
                <span class="text-h3  font-weight-bold ">
                    Total Potential Taxes <br> with Adjustments <sup>2</sup>
                </span>
            </v-card-title>
            <v-card-title  v-else-if="!isCalculated" class="white--text d-flex justify-center ">
                <span class="text-h3  font-weight-bold ">
                    Total Potential Taxes with <br> Existing Approach <sup>1</sup>
                </span>
            </v-card-title>

            <v-card-text class="pt-4 px-5  text-center d-flex"  v-if="isCalculated" >
				<v-row>
					<v-col class="sw_white  elevation-10">
						<span class="text-h1">{{ totalTaxesConversion | currency('$', 0) }}</span>
					</v-col>
				</v-row>
            </v-card-text>
            <v-card-text class="pt-4 px-5  text-center d-flex "  v-else>
				<v-row>
					<v-col class="sw_white  elevation-10">
						<span class="text-h1">{{ totalTax | currency('$', 0) }}</span>
					</v-col>
				</v-row>
            </v-card-text>


        </v-card>
</template>
<script>
import Vue from 'vue'
import Vue2Filters from 'vue2-filters'
Vue.use(Vue2Filters)

export default {
    name: 'calcCardCondense',
    props: {
        isCalculated: {
            type: Boolean, 
            default: false
        }
    },
    data: () => ({
        fundsAnalized: "",
        taxRate: "",
    }),
    created() {    
        this.totalTax = this.$store.state.user.taxPaid;
        this.totalTaxesConversion = this.$store.state.user.totalTaxesConversion;
        
    },
}
</script>