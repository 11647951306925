<template>
<div>
	<component :is="layout">
		<router-view />
	</component>

    <!-- <v-app-bar
      app
      color="white"
      light
      elevation="2"
      height="110"
      class="appbar"
    >
      <v-row class="pa-5 d-flex align-center">
      <Header :headerLogo="integrateIQlogo" :companyName="integrateIQname"> </Header>
      </v-row>
    </v-app-bar>
    <v-main>
      <router-view />
      <AppFooter></AppFooter>
    </v-main> -->
</div>

</template>
<script>
import Header from './components/Header.vue';
import AppFooter from './components/Footer.vue';
import DefaultLayout from "./layouts/default.vue";

export default {
	name: 'App',
	components: {
		Header,AppFooter
	},
	computed: {
		layout() {
			return this.$route.meta.layout || DefaultLayout;
		},
	},
	data: () => ({
		integrateIQlogo: require("@/assets/logos/art.png"),
		integrateIQname: 'Integrate IQ'
	}),
};
</script>
<style>
  @import 'assets/css/styles.css';
</style>