<template>
	<div class="pa-0 ma-0">
		<v-banner min-height="77" outlined color="sw_safron" class="d-flex align-center justify-center">  
			<template v-slot:default>
				<v-row>
					<v-col cols="12" class="text-center d-flex align-center justify-center">
						<v-icon small class="sw_textDarkBlue--text mr-2">$iconCalculator</v-icon>
						<h4 class="sw_textDarkBlue--text text-h4 font-weight-medium">Discover Your Retirement Tax Bill: Free Calculator!</h4>
					</v-col>
				</v-row>
			</template>
		</v-banner>
		<v-sheet min-height="800px" id="leadGenHero" class="fill-height d-flex align-center justify-center">
			<v-container class="py-16">
				<v-row class="d-flex align-center justify-center">
					<v-col cols="10" md="6" :class="$vuetify.breakpoint.mdAndUp ? 'pr-16':'pr-0'">
						<h4 class="heroH4 white--text">Uncover Your Potential Retirement Tax Bill in 3 Easy Steps</h4>
						<h1 class="heroH1 wWrap white--text py-3"> <span class="sw_safron--text mr-3"> Prepare for Retirement Taxes: </span> Calculate, Evaluate, Act</h1>
						<h4 class="heroH4 white--text">Get a free, immediate report of your potential liability.</h4>
					</v-col>
					<v-col cols="10" md="6">
						<v-sheet color="sw_safron" min-height="600" class="pa-5">
							<CalculationForm :formFocus="focusOnForm"></CalculationForm>            
						</v-sheet>
					</v-col>
				</v-row>
			</v-container>
		</v-sheet>
		<v-banner min-height="77" outlined color="sw_bgDarkBlue" class="d-flex align-center justify-center">  
			<template v-slot:default>
				<v-row>
					<v-col cols="12" class="text-center ">
						<h4 class="white--text text-h4 font-weight-medium"> 
							100% Free Tax Report  <v-icon class="white--text" large>mdi-circle-small</v-icon>  Instant Results  <v-icon class="white--text" large>mdi-circle-small</v-icon>   Privacy Protected
						</h4>
					</v-col>
				</v-row>
			</template>
		</v-banner>
		<v-sheet min-height="410px" class="fill-height d-flex align-center justify-center">
			<v-container class="py-10">
				<v-row class="py-0">
					<v-col cols="12" md="3" class="text-center py-5">
						<v-icon class="constraint150by100 sw_bgLightBlue--text pb-15">
							$iconComputer
						</v-icon>
						<h2 class="font-weight-medium text-h2 sw_oxfordBlue--text">Step 1:</h2>
						<h3 class="font-weight-light sw_oxfordBlue--text constraint text-h3  mt-3">Enter Your <br> information</h3>
					</v-col>
					<v-spacer></v-spacer>
					<v-col cols="12" md="3" class="text-center py-5">
						<v-icon class="constraint150by100 sw_bgLightBlue--text  pb-15">$iconEnvelope</v-icon>
						<h2 class="font-weight-medium text-h2 sw_oxfordBlue--text">Step 2:</h2>
						<h3 class="font-weight-light sw_oxfordBlue--text text-h3 mt-3">Receive Immediate <br> Results</h3>

					</v-col>
					<v-spacer></v-spacer>
					<v-col cols="12" md="3" class="text-center py-5">
						<v-icon class="constraint150by70 sw_bgLightBlue--text pb-15">$iconPercentage</v-icon>
						<h2 class="font-weight-medium text-h2 sw_oxfordBlue--text">Step 3:</h2>
						<h3 class="font-weight-light sw_oxfordBlue--text text-h3  mt-3">Discover Options to <br> Address Your Tax Liability </h3>

					</v-col>
				</v-row>
			</v-container>
		</v-sheet>
		<v-sheet min-height="460px" color="sw_bgDarkBlue" class="fill-height d-flex align-center justify-center pa-5">
			<v-container>
				<v-row class="d-flex justify-center align-center">
					<v-col cols="12" md="6" class="text-left py-5">
						<h3 class="text-h3 font-weight-bold sw_safron--text ">Maximize Retirement Savings:</h3>
						<h3 class="text-h3 white--text font-weight-light pb-5">Uncover Potential Hidden Taxes!</h3>
						<ul class="white--text pl-0" style="list-style-type: none;">
							<li class="pb-3"> <v-icon class="white--text">mdi-arrow-right</v-icon> Reveal your potential tax bill in 3 easy steps</li>
							<li class="pb-3"> <v-icon class="white--text">mdi-arrow-right</v-icon> Get a FREE tax report with our user-friendly calculator</li>
							<li class="pb-3"> <v-icon class="white--text">mdi-arrow-right</v-icon> Explore tailored options to potentially minimize tax obligations</li>
							<li class="pb-3"> <v-icon class="white--text">mdi-arrow-right</v-icon> Secure your financial future and stay ahead</li>
						</ul>
					</v-col>
					<v-col cols="12" md="6" class="text-left">
						<iframe
							style="width: 100%; height: auto; min-height: 400px;"
							src="https://player.vimeo.com/video/605875159?h=1e06f9d73c"
							frameborder="0"
							webkitallowfullscreen
							mozallowfullscreen
							allowfullscreen
						></iframe>
					</v-col>
					
				</v-row>
			</v-container>
		</v-sheet>
		<v-container>
			<v-sheet color="sw_safron" class="my-10 pa-5">
				<v-container class=" sw_safron fill-height">
					<v-row align="stretch" class="d-flex align-center stretch">
						<v-col cols="12" md="6" class="sw_bgDarkBlue--text d-flex flex-grow-1 flex-column justify-space-around">
							<h3 class="bannerH3">
								Quickly Uncover your Potential
								Tax Burden in 3 Easy Steps
							</h3>
							<h1 class="bannerH1 py-5">Prepare for Retirement Taxes: Calculate, Evaluate, Act</h1>
							<h3 class="bannerH3">
								Get a free, immediate report of your potential liability.
							</h3>
						</v-col>
						<v-col cols="12" md="6" class="sw_bgDarkBlue--text text-center d-flex flex-grow-1 flex-column justify-space-around align-center">
							<v-btn color="sw_bgLightBlue" dark class="text-h4 font-weight-bold btn-lowerCase mt-5"> Get Free Tax Report</v-btn>
							<h2 class="font-weight-medium text-h4 mt-5">
								<div>100% Free Report </div>
								<div>Instant Results <v-icon class="sw_bgDarkBlue--text" x-large>mdi-circle-small</v-icon>  Privacy Protected</div>
							</h2>
							
						</v-col>
					</v-row>
				</v-container>
			</v-sheet>
		</v-container>

	</div>
</template>
<script>
import CalculationForm from "../components/FormLeadGen.vue";

export default {
    components: {
        CalculationForm,
    },
    data: () => ({
        heroImage: require("@/assets/images/herobg.png"),
        focusOnForm: false,
    }),
};
</script>
<style>
	#leadGenHero {
		background-image: url(../assets/images/TTB_LPHeader_0921.webp);
		background-size: cover;
		background-position: center;
	}
</style>