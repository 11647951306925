<template>
	<div class="full-height">

		<v-container fluid class="pa-0">
			<v-banner min-height="77" outlined color="sw_safron" class="d-flex align-center justify-center">  
				<template v-slot:default>
					<v-row>
						<v-col cols="12" class="text-center d-flex flex-column align-center justify-center">
							
							<h4 class="sw_textDarkBlue--text text-h4 font-weight-medium">Now you know your Potential Retirement Tax Bill!  <v-icon small class="sw_textDarkBlue--text mr-2">$iconCalculator</v-icon></h4>

							<h4 class="sw_textDarkBlue--text text-h4 font-weight-medium mt-5">Our team is here to help!</h4>
						</v-col>
					</v-row>
				</template>
			</v-banner>
			<v-sheet min-height="800px" id="leadGenHero" class="fill-height pa-5 d-flex align-center justify-stretch">
				<v-container>
					<v-row align="stretch">
						<v-col cols="12">
							<v-row>
								<v-col cols="12" md="6">
									<CalcCardCondense :isCalculated="!hasCalculation"></CalcCardCondense>
								</v-col>
								<v-col cols="12" md="6">
									<CalcCardCondense :isCalculated="hasCalculation"></CalcCardCondense> 
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" md="6" offset-md="3">
									<v-card class="sw_safron mx-auto my-4  fill-height">
										<v-card-title class="text-center d-flex justify-center"> 
											<span class="text-h3  font-weight-bold sw_oxfordBlue--text">
												Potential Tax Difference <br> Between Approaches
											</span>
										</v-card-title>

										<v-card-text class="pt-4 px-6 text-center d-flex">
											<v-row>
												<v-col cols="12" class="sw_white  elevation-10">
													<span class="text-h1">{{  totalTax - totalTaxesConversion  | currency('$', 0) }}</span>
												</v-col>
											</v-row>
										</v-card-text>
									</v-card>
								</v-col>
							</v-row>
							<v-row class="mt-10">
								<v-col class="text-center" cols="12" md="6" offset-md="3">
									<v-btn dark rounded block
										class="multi-line"
										@click="scheduleAction"
										color="sw_btnBlue"
										large
									>
										Review your results with a Financial Professional
									</v-btn>          
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-container>
			</v-sheet>
			<v-banner min-height="77" outlined color="sw_bgDarkBlue" class="d-flex align-center justify-center">  
				<template v-slot:default>
					<v-row>
						<v-col cols="12" class="text-center ">
							<h4 class="white--text text-h4 font-weight-medium my-3"> 
								Here are your next 3 steps:
							</h4>
						</v-col>
					</v-row>
				</template>
			</v-banner>
			<v-sheet min-height="410px" class="fill-height d-flex align-center justify-center">
				<v-container class="py-10">
					<v-row class="py-0">
						<v-col cols="12" md="3" class="text-center py-5">
							<v-icon class="constraint150by100 sw_bgLightBlue--text  pb-15">
								$iconReview
							</v-icon>
							<h2 class="font-weight-medium text-h2 sw_oxfordBlue--text">Review Your Results</h2>
							<h3 class="font-weight-light sw_oxfordBlue--text text-h4  mt-3">Now, you can see the potential taxes with your existing approach - and your potential taxes with an adjusted approach.</h3>
						</v-col>
						<v-spacer></v-spacer>
						<v-col cols="12" md="3" class="text-center py-5">
							<v-icon class="constraint150by100 sw_bgLightBlue--text pb-15">
								$iconConsider
							</v-icon>
							<h2 class="font-weight-medium text-h2 sw_oxfordBlue--text">Consider Your Options </h2>
							<h3 class="font-weight-light sw_oxfordBlue--text text-h4 mt-3">You may have options to reduce your tax bill in retirement. Learn more now.</h3>

						</v-col>
						<v-spacer></v-spacer>
						<v-col cols="12" md="3" class="text-center py-5">
							<v-icon class="constraint150by120 sw_bgLightBlue--text pb-15">
								$iconCheck
							</v-icon>
							<h2 class="font-weight-medium text-h2 sw_oxfordBlue--text">Check Your Inbox </h2>
							<h3 class="font-weight-light sw_oxfordBlue--text text-h4  mt-3">Check your email to review your complete report. Don't see your report in your inbox? Schedule a call and we'll review it. </h3>

						</v-col>
					</v-row>
					<v-row>
						<v-col class="text-center" cols="12" md="8" offset-md="2">
							<v-btn
								@click="scheduleAction"
								color="sw_safron"
							>
								<h3 class="font-weight-medium">Schedule a Call</h3>
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</v-sheet>
			
		</v-container>



		<!-- <v-container fluid class="hero-compact flex-column">
			<div class="hero-compact-bg" :style="{backgroundImage: 'url('+require('@/assets/images/herobg.png')}"></div>
			<v-container class="my-6">
			<v-row class="pt-5">
				<v-col cols="12" align="center" class="gray--text">
				<h1>Congratulations!  You're Ready to Understand Your Retirement Tax Bill!</h1>
				<h2>Our team is here to help.</h2>
				</v-col>
			</v-row>
			<v-row class="d-flex align-stretch">
				<v-col cols="12" md="6">
				<CalcCardCondense :isCalculated="!hasCalculation"></CalcCardCondense>
				</v-col>
				<v-col cols="12" md="6">
				<CalcCardCondense :isCalculated="hasCalculation"></CalcCardCondense> 
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" class="mt-6" >
					<v-btn rounded block
					class="multi-line"
					@click="scheduleAction"
					color="stonewood_primary"
					large
					>
					Review your results with a Financial Professional
					</v-btn>          
				</v-col>
			</v-row>
			</v-container>
		</v-container> -->
		<!-- <v-container>
			<v-row>
			<v-col class="text-center mt-5" cols="12">
				<h3> Here are your next three steps.</h3>
			</v-col>
			</v-row>
			<v-row>
			<v-col cols="12" md="4" class="text-center">
			<v-img
				:src="require('../assets/images/report.png')"
				class="my-3"
				contain
				height="150"
				/>
				<h3> REVIEW YOUR RESULTS </h3>
				<p>Now, you can see the potential taxes with your existing approach - and your potential taxes with an adjusted approach.</p>
			</v-col>
			<v-col cols="12" md="4" class="text-center">
				<v-img
				:src="require('../assets/images/options.png')"
				class="my-3"
				contain
				height="150"
				/>
				<h3> CONSIDER YOUR OPTIONS </h3>
				<p>You may have options to reduce your tax bill in retirement. Learn more now.</p>
			</v-col>
			<v-col cols="12" md="4" class="text-center">
				<v-img
				:src="require('../assets/images/mail.png')"
				class="my-3"
				contain
				height="150"
				/>
				<h3> CHECK YOUR INBOX </h3>
				<p>Check your email to review your complete report. Don't see your report in your inbox? Schedule a call and we'll review it.</p>
			</v-col>
			
			</v-row>
			<v-row>
			<v-col class="text-center" cols="12" md="8" offset-md="2">
				<v-btn
				@click="scheduleAction"
				class="ma-2 text-truncate"
				rounded
				color="secondary"
				>
				Schedule a Call
				</v-btn>
			</v-col>
			</v-row>
		</v-container> -->
		<v-container fluid class="sw_bgDarkBlue white--text">
			<v-container>
			<v-row>
				<v-col class="mt-5">
				<div class="text-h4">{{firstName}} {{lastName}}  Age:  <b>{{age}}</b></div>
				<div class="caption d-block text-no-wrap pr-2">Tax-Deferred Funds for Analysis: <b>{{fundsAnalized  | currency('$', 0) }}</b></div>
				<div class="caption d-block text-no-wrap pr-2">Assumed Effective Tax Rate: <b>{{taxRate}}%</b></div>
				<div class="caption d-block text-no-wrap pr-2">Assumed Growth Rate for Funds: <b>{{growRate}}</b></div>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
				<p>This analysis is based on the following additional assumptions.  Please check your email for your custom, comprehensive report.</p>
				<ul class="my-5">
					<li>You continue to keep your IRA, 401(k), or other qualified account</li>
					<li>You take Required Minimum Distributions (RMDs) when required</li>
					<li>You reinvest those RMDs in a taxable account earning an equivalent after-tax rate</li>
					<li>You live until age 90</li>
					<li>Your heirs are taxed at the same rate you have selected above</li>
				</ul>
				<p>This is an analysis of the total potential taxes you may pay from your IRA or 401(k), based on what we believe are reasonable, forward-looking assumptions and scenarios. A complete analysis has been emailed to you.</p>
				<p>Next Steps? We're here to help.</p>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" class="d-flex justify-center">
					<v-btn
						@click="scheduleAction"
						color="sw_safron"
					>
				Schedule a Call! 
					</v-btn>
				</v-col>
			</v-row>
			</v-container>
		</v-container>
		<!-- <v-container>
			<v-row>
				<v-col class="text-center" cols="12" md="8" offset-md="2">
				<v-btn
					@click="scheduleAction"
					class="ma-2 text-truncate"
					rounded
					color="secondary"
					large
				>
					Schedule a Call
				</v-btn>
				</v-col>
			</v-row>
			<v-row class="d-flex align-center">
				<v-col cols="12" md="6">
				<p>
					This is an analysis of the total potential taxes you may pay from your
				IRA or 401(k), based on what we believe are reasonable,
				forward-looking assumptions and scenarios. A complete analysis has been
					emailed to you.
				</p>
				<h2>Next Steps? We're here to help.</h2>
				<p>
				The team at {{company_name}} can help you understand what this
					potential tax bill means to YOUR retirement. Schedule time with our
					team and discuss how we might help you lower your reitrement tax bill.
				</p>
				</v-col>
				<v-col cols="12" md="6">
				<CalculationCard></CalculationCard>
				</v-col>
			</v-row>
			<v-row class="gray--text">
				<v-col>
				<h3>
					This analysis is based on the following additional assumptions.
					<small class="d-block"
					>Please check your email for your custom, comprehensive
					report.</small
					>
				</h3>
				<ul>
					<li>
					You continue to keep your IRA, 401(k), or other qualified account
					</li>
					<li>You take Required Minimum Distributions (RMDs) when required</li>
					<li>
					You reinvest those RMDs in a taxable account earning an equivalent
					after-tax rate
					</li>
					<li>You live until age 90</li>
					<li>Your heirs are taxed at the same rate you have selected above</li>
				</ul>
				</v-col>
			</v-row>
			<v-row> </v-row>
		</v-container> -->
			<v-dialog v-model="beforeExitDialog" width="500">
				<v-card>
					<v-card-title
					class="text-h5 grey lighten-2 d-flex justify-space-between"
					>
					Wait!
					<v-btn icon light @click="beforeExitDialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					</v-card-title>

					<v-card-text class="mt-4">
					<p>
						Did you know many experts believe taxes are going UP for retirees?
					</p>
					<p>
						See if you’ll be impacted – and how much bigger your Retirement Tax
						Bill could be.
					</p>
					<v-col class="text-center" cols="12" md="8" offset-md="2">
						<v-btn
						@click="scheduleAction"
						class="ma-2 text-truncate"
						rounded
						color="secondary"
						large
						>
						Schedule a Call
						</v-btn>
					</v-col>
					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="beforeExitDialog = false"
						>Close</v-btn
					>
					</v-card-actions>
				</v-card>
			</v-dialog>
	</div>
</template>
<script>
	// import CalculationCard from "../components/CalculationResult.vue";
	import CalcCardCondense from "../components/CalcCardCondense.vue";
	import $ from "jquery";
	import Vue from "vue";
	import axios from "axios";
	import VueAxios from "vue-axios";
	import Vue2Filters from 'vue2-filters'
	/**/
	Vue.use(VueAxios, axios);
	Vue.use(Vue2Filters)

	var qs = require("qs");

	export default {
	created() {

		this.company_name = this.$store.state.company.company_name;
		this.meeting_url = this.$store.state.company.meeting_url;
		this.advisorId = this.$store.state.user.advisorId;
		this.email = this.$store.state.user.email;
		this.proposalId = this.$store.state.user.proposalId;

			this.totalTax = this.$store.state.user.taxPaid;
			this.totalTaxesConversion = this.$store.state.user.totalTaxesConversion;
			
			this.firstName = this.$store.state.user.first_name;
			this.lastName = this.$store.state.user.last_name;
			this.age = this.$store.state.user.age;
			this.fundsAnalized = this.$store.state.user.taxDeferred;
			this.taxRate = this.$store.state.user.taxRate;
			this.meeting_url = this.$store.state.company.meeting_url;

		var beforeUnloadTimeout = 0;
		let proxy = this;
		$(window).bind("beforeunload", function () {
		console.log("beforeunload");
		beforeUnloadTimeout = setTimeout(function () {
			proxy.onUnloadCheckUp(true);
			console.log("settimeout function");
		}, 500);
		return "are you sure";
		});
		$(window).bind("unload", function () {
		console.log("unload");
		if (
			typeof beforeUnloadTimeout !== "undefined" &&
			beforeUnloadTimeout != 0
		)
			clearTimeout(beforeUnloadTimeout);
		});

		var data = qs.stringify({
			advisorId: this.advisorId,
			email: this.email,
			proposalId : this.proposalId,
			firstName : this.firstName
		});
		var config = {
			method: "post",
					url: 'https://app.stonewoodfinancial.com/scripts/proposals/rmd/tax-burden-lead-send-email.php',
			headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			},
			data: data,
		};
		axios(config)
			.then(function (response) {
			console.log("entro con: " + response);         
			})
			.catch(function (error) {
			console.log(error);
			});

	},
	mounted() {
		window.scrollTo(0, 0)
	},	
	components: {
		CalcCardCondense
	},
	data: () => ({
		company_name: "",
		meeting_url: "",
		advisorId: "",
		email: "",
		beforeExitDialog: false,
		hasCalculation: true,
		totalTaxesConversion: "",
		firstName: "",
		lastName: "",
		age: 0,
		fundsAnalized: "",
		taxRate: "",
		growRate: "5%",
		totalTax: "",
	}),
	methods: {
		scheduleAction() {
		window.open(this.meeting_url, "_blank");
		},
		onUnloadCheckUp(state) {
		let proxy = this;
		proxy.beforeExitDialog = state;
		},
	},
};
</script>