<template>
  <v-row
      id="landingHeader"
      class="d-flex flex-row justify-space-between align-md-center align-start flex-column flex-md-row"
    >
    <noscript v-if='gtm_code !== ""'><iframe v-bind:src="getGTMIFrameUrl()" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <div class="brand d-flex flex-row align-center flex-grow-1">
        <v-img
          :src="headerLogo"
          class="my-0 flex-grow-0"
          contain
          max-height="80"
          max-width="120"
          position="center center"
        />
        <div id="companyName" class="pl-1 black--text text-h4 text-md-h2 text-md-left text-right flex-md-grow-1 font-weight-regular">{{ companyName }} </div>
    </div>  
    <h2 class="ml-1 ml-md-auto align-self-end align-self-md-center  text-h6 text-md-h3 text-left text-md-right"> <span class="d-inline-block text-no-wrap"> {{phone}} </span></h2>

    <v-dialog v-model="dialog" persistent max-width="575">
      <v-card>
        <v-card-title class="text-h5"> You Need access </v-card-title>
        <v-card-text>This page is no longer active</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>  
  
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
/**/
Vue.use(VueAxios, axios);

export default {
  created() {
    var axios = require("axios");
    var qs = require("qs");
    var data = qs.stringify({
      u: this.$route.query.u,
    });
    var config = {
      method: "post",
      url: "https://app.stonewoodfinancial.com/scripts/proposals/rmd/get-client-branding.php",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };
    var self = this;
    axios(config)
      .then(function (response) {
        self.headerLogo = response.data.company_logo;
        if (response.data.company_name == "") {
          self.companyName = "Discover your Retirement Tax Bill";
        } else {
          self.companyName = response.data.company_name;
        }
        self.fullName = response.data.userFullName;
        self.phone = response.data.phone;
        self.gtm_code = response.data.gtm_code;
        self.lead_capture_disclosure = response.data.lead_capture_disclosure;
        self.$store.state.company.company_name = self.companyName;
        self.$store.state.company.userFullName = self.userFullName;
        self.$store.state.company.phone = self.phone;
        self.$store.state.company.lead_capture_disclosure =
          self.lead_capture_disclosure;
        self.$store.state.company.meeting_url =
          response.data.lead_capture_meet_url;
        if (response.data.lead_allow == false) {
          self.dialog = true;
        }
        if (self.gtm_code != "") {
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer',self.gtm_code);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
   methods: {
    getGTMIFrameUrl(){
      return "https://www.googletagmanager.com/ns.html?id=" + this.gtm_code
    }
  },
  name: "landingHeader",
  data: () => ({
    headerLogo: "",
    companyName : "",   
    fullName : "",
    phone: "",
    gtm_code: "",
    dialog : false, 
  })
};
</script>