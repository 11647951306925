<template>

		<v-row>
			<v-col cols="12" md="12" > 
				<v-card :elevation="0" color="sw_safron">
					<v-card-title class="mb-10 text-center wbreak heroFormTitle sw_textDarkBlue--text d-flex justify-center">
						Potential Tax Bill Calculator
					</v-card-title>
					<v-card-text :class="$vuetify.breakpoint.smAndDown ? 'pa-0': ''">
						<v-stepper flat class="sw_safron" v-model="step">
							<!-- Step Headers -->
							<!-- Step 1 Content -->
							<v-stepper-items>
								<v-stepper-content class="pa-0" step="1">
									<v-form ref="form1" v-model="step1Valid">
										<v-row>
											<v-col class="pb-0" cols="12">
												<div class="d-flex flex-column">
													<label class="sw_black--text body-1">{{ labelAge }}</label>
													<vuetify-money
														ref="formAge"
														class="
															flex-grow-1
															black--text
														"
														color="black"
														outlined
														:rules="ageRules"
														v-model="age"
														v-bind:placeholder="placeholderAge"
														v-bind:readonly="readonly"
														v-bind:disabled="disabled"
														v-bind:clearable="clearable"
														v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
														v-bind:options="optionsAge"
														v-bind:properties="propertiesAge"
														>
													</vuetify-money>
												</div>
											</v-col>
											<v-col class="pb-0" cols="12">
												<div class="d-flex flex-column">
													<label class="sw_black--text body-1">{{ label }}</label>
													<vuetify-money
													class="
														flex-grow-1
														black--text
													"
													required
													outlined
													v-model="ira_value"
													v-bind:placeholder="placeholder"
													v-bind:options="options"
													v-bind:properties="properties"
													:rules="ira_valueRules"
													>
													</vuetify-money>
												</div>
											</v-col>
											<v-col cols="12">
												<label class="sw_black--text body-1">{{ labelTaxRate }}</label>
												<v-slider 
													class="mt-13 mb-5 pl-4 pr-4"                   
													:readonly= useTaxRateDefault
													v-model="tax_rate"
													color="sw_oxfordBlue"
													dense
													max="40" 
													min="15"
													step="5"
													value="25"
													thumb-label="always"
													thumb-size="45"
													ticks="always"
													tick-size="2"
													track-color="sw_safron darken-1"
													track-fill-color="sw_oxfordBlue"
													:tick-labels="[15, 20, 25, 30, 35, 40]"
													>
													<template v-slot:thumb-label="data">
														{{ data.value }}%	
													</template>
												</v-slider>
												<v-checkbox
													class="mt-n3 mb-1"
													@change="taxRateToDefault"
													v-model="useTaxRateDefault"
													label=" Don't Know? Use the default tax rate (25%)"
												></v-checkbox>
												
											</v-col>
											<v-col>
												<v-btn small :disabled="!step1Valid" class="sw_bgLightBlue multi-line btn-lowerCase sw_white--text" rounded block @click="goToStep2">Continue to final Step</v-btn>
											</v-col>
										</v-row>
									</v-form>
								</v-stepper-content>
						
								<!-- Step 2 Content -->
								<v-stepper-content class="pa-0" step="2">
									<v-form ref="form2" v-model="step2Valid">
										<v-row no-gutters class="d-flex flex-row align-center">
											
											<v-col class="sw_textDarkBlue--text" cols="12">
												<h3>Your analysis is ready!</h3>
												<p>Complete the details below for delivery.</p>											
											</v-col>
											
											<v-col class="pb-0" cols="12" >
												<label class="sw_black--text body-1">First name *</label>
												<v-text-field
													ref="formFirstName"
													v-model="first_name"
													:rules="nameRules"												
													required														
													class="black--text v-input-white"
													outlined
												></v-text-field>
											</v-col>
											<v-col class="pb-0" cols="12">
												<label class="sw_black--text body-1">Last name *</label>
												<v-text-field
													v-model="last_name"
													:rules="nameRules"
													required
													class="black--text v-input-white"
													outlined
												></v-text-field>
											</v-col>
											<v-col class="pb-0" cols="12">
												<label class="sw_black--text body-1">E-mail *</label>
												<v-text-field
													@keydown.space.prevent
													v-model="email"
													:rules="emailRules"
													required
													class="black--text v-input-white"
													outlined
												></v-text-field>
											</v-col>
											<v-col cols="12">
												<label class="sw_black--text body-1">Phone Number</label>
												<v-text-field
													v-model="phone"
													:rules="phoneRules"
													label=""
													class="black--text v-input-white"
													outlined
													hint="Enter your phone number. Optional"
												></v-text-field>
											</v-col>
											<v-col class="pt-3 d-flex align-center" cols="12">
												<v-btn icon x-large @click="goToStep1">
													<v-icon>mdi-chevron-left</v-icon>
												</v-btn>
												<v-btn small :disabled="!step2Valid" color="sw_bgLightBlue" class="btn-lowerCase multi-line flex-grow-1 sw_white--text" rounded  @click="sendProposal">See my Bill</v-btn>
											</v-col>
										</v-row>
										
										
									</v-form>
								</v-stepper-content>
							</v-stepper-items>
						</v-stepper>
						<!-- <v-row class="" >
							<v-form class="w-100" style="width: 100%;">
								
								<v-col>
									<v-slider 
										class="mt-0"                   
										prepend-icon="mdi-brightness-percent"
										append-icon="mdi-percent-outline"
										label="Average Tax Rate"
										:readonly= useTaxRateDefault
										v-model="tax_rate"
										dense 
										max="40" 
										min="15"
										step="5"
										value="25"
										thumb-label="always"
										thumb-size="23"
										ticks
										>
									</v-slider>
									<v-checkbox
										class="mt-n3 mb-1"
										@change="taxRateToDefault"
										v-model="useTaxRateDefault"
										label=" Don't Know? Use the default tax rate (25%)"
									></v-checkbox>
								</v-col>
							</v-form>
						</v-row> -->
						
					</v-card-text>
					<!-- <v-divider></v-divider>
					<v-card-actions> 
						<v-col class="text-center">
							<v-btn
							color="stonewood_primary"
							class="my-1"
							type="submit"
							:disabled="!valid"
							rounded
							large
							>
							See My Bill
							</v-btn>
							</v-col>
					</v-card-actions> -->
				</v-card>
			</v-col>
		</v-row>      
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

/**/
Vue.use(VueAxios, axios);

var qs = require("qs");

export default {

  components: {},

  name: "CalculationForm",
  props: {
    formFocus: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({

	step: 1,
	step1Valid: false,
	step2Valid: false,
	form1: {
	name: '',
	},
	form2: {
	email: '',
	},

    numberRule: v  => {
      if (!v.trim()) return true;
      if (!isNaN(parseFloat(v)) && v >= 20 && v <= 90) return true;
      return 'Age has to be between 20 and 90';
    },
	labelTaxRate: "Average Tax Rate*",
    label: "Current IRA or 401(k) Value*",
    labelAge: "Enter Current Age *",    
    placeholderAge: "00",
    advisorId:"",
    placeholder: "1000",
    useTaxRateDefault: false,
    readonly: false,
    disabled: false,
    outlined: true,
    clearable: false,
    valueWhenIsEmpty: "",
    options: {
      locale: "en-US",
      suffix: "",
      length: 11,
      precision: 0,
    },
        iraRules: [
      v => !!v || "This field is required"

    ],
    optionsAge: {
      locale: "en-US",
      suffix: "",
      length: 2,
      precision: 0,
    },
    properties: {
      hint: "Enter amount",
      // You can add other v-text-field properties, here.
    },
    propertiesAge: {
      hint: "Enter Current Age or Age of Oldest Spouse",
      // You can add other v-text-field properties, here.
    },
    activePicker: null,
    menu: false,
    valid: false,
    isTaxable: false,
    dob: null,
    first_name: "",
    last_name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => !/\//.test(v) || 'Invalid name', 

    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,12})+$/.test(v) ||
        "E-mail must be valid",
    ],
    phone: "",
    phoneRules: [
      (v) =>
        !v ||
        /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(v) ||
        "Phone number must be valid",
    ],
    age: "",
    ageList: [
       1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
    ],
    ageRules: [
		v => !!v || "This field is required",
		v => ( v && v >= 20 ) || "Age should be greater than 20",
		v => ( v && v <= 91 ) || "Age should not be above 90",
    ],
    ira_value: "",
    ira_valueRules: [
		v => !!v || "This field is required",
		(v) => !!v || "This amount is required for the calculation",
		(v) => parseFloat(v) > 0 || "Please enter a value greater than 0"
    ],
    tax_rate: "",
    LiabilityPercentages: ["15%", "20%", "30%"],
    tax_rateRules: [
		(v) => !!v || "This Percentage is required for the calculation",
		v => ( v && v >= 15 ) || "Tax Rate should be greater than 15%",
		v => ( v && v <= 40 ) || "Tax Rate should not be above 40%",
    ],
  }),
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    formFocus: function(){
      this.focunOnForm();
    }
  },
  methods: {
    // focunOnForm(){
    //     //this.$refs["formFirstName"].focus();     
    // },
	goToStep2() {
		window.scrollTo(0, 0);
		this.step = 2;
	},
	goToStep1()	{
		window.scrollTo(0, 0);
		this.step = 1;
	},
    focunOnForm() {
		this.$nextTick(() => {
		// Find the actual input element inside the custom vuetify-money component
		const ageInput = this.$refs.formAge.$el.querySelector('input');
		if (ageInput) {
			ageInput.focus(); // Focus the input element
		}
		});
	},
    taxRateToDefault(){
		if(this.useTaxRateDefault){
			this.tax_rate = 25;
		}else{
			this.tax_rate = 25;
		}
    },
    sendProposal() {
		this.advisorId = this.$route.query.u;
		var userDobYear = parseInt(new Date().getFullYear()) - parseInt(this.age);
		var currentDob = userDobYear + "-01-01";
		var data = qs.stringify({
			first_name: this.first_name,
			last_name: this.last_name,
			phone: this.phone,
			email: this.email,
			dob: currentDob,
			ira_value: this.ira_value,
			tax_rate: this.tax_rate,
			isTaxable: false,
			advisorId: this.$route.query.u,
		});
		var config = {
			method: "post",
					url: 'https://app.stonewoodfinancial.com/scripts/proposals/rmd/tax-burden-lead.php',
			headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			},
			data: data,
		};
		var self = this;
		axios(config)
			.then(function (response) {
			console.log("entro con: " + response);
			self.$store.state.user.taxPaid = response.data.totalTax;   
			self.$store.state.user.totalTaxesConversion = response.data.totalTaxesConversion;    
				
			self.$store.state.user.age = self.age;
			self.$store.state.user.taxDeferred = self.ira_value;
			self.$store.state.user.taxRate = self.tax_rate;
			self.$store.state.user.first_name = self.first_name;
			self.$store.state.user.last_name = self.last_name;
			self.$store.state.user.email = self.email;
			self.$store.state.user.advisorId = self.advisorId;
			self.$store.state.user.proposalId = response.data.encryptedId;
			
			self.$router.push({ path: "/analysis" });
			})
			.catch(function (error) {
			console.log(error);
			});
    },
    save(date) {
		this.$refs.menu.save(date);
    },
  },
};
</script>