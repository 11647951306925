import Vue from 'vue'
import VueRouter from 'vue-router'
import Analysis from '../pages/Analysis.vue'

//new

import LandingLeadGen from '../pages/LandingLeadGen.vue'

//layouts
import DefaultLayout from '../layouts/default.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
	component: LandingLeadGen,
    meta: { gtm: "TTB home", layout: DefaultLayout }

  },
  {
    path: '/analysis',
    name: 'Analysis',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Analysis,
    meta: { gtm: "TTB analysis" }
  },

  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
