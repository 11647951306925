<template>
	<v-app>
	<!-- Top App Bar -->
		<noscript v-if='gtm_code !== ""'><iframe v-bind:src="getGTMIFrameUrl()" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>

		<v-app-bar  min-height="105" height="105" app color="white">
			<v-container>
				<v-row class="d-flex align-center flex-nowrap">
					<v-toolbar-title class="px-5">
						<a  :href="formatURL(companyWebsite)" target="_blank" rel="noopener noreferrer">
							<v-img position="center left" v-if="headerLogo  !== ''"  :src="headerLogo" max-height="85" max-width="300" contain></v-img>
							<!-- <v-img v-else :src="require('../assets/logos/stonewood.webp')" max-height="100" contain></v-img> -->
							<!-- <h3 v-else>{{companyName}}</h3> -->
						</a>
					</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-btn text x-large :href="`tel:${phone}`" target="_blank" class="font-weight-bold sw-phone-typography" >{{phone}}</v-btn>
				</v-row>
			</v-container>
		</v-app-bar>

		<!-- Main Content -->
		<v-main>
			<v-container fluid class="pa-0 mb-0">
				<router-view />
			</v-container>
		</v-main>

		<v-dialog v-model="dialog" persistent max-width="575">
			<v-card>
				<v-card-title class="text-h5"> You Need access </v-card-title>
				<v-card-text>This page is no longer active</v-card-text>
			</v-card>
		</v-dialog>

		<!-- Footer -->
		<AppFooter></AppFooter>
		<v-footer  class="pa-0" color="sw_oxfordBlue" dark>
			<v-col class="text-center body-2 sw-white--text font-weight-medium ma-10">Copyright  &copy; Stonewood Financial {{ new Date().getFullYear() }}. All Rights Reserved.</v-col>
		</v-footer>
	</v-app>
</template>

<script>
import AppFooter from '@/components/Footer.vue';
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
/**/
Vue.use(VueAxios, axios);

export default {
	components: {
		AppFooter
	},
	created() {
		var axios = require("axios");
		var qs = require("qs");
		var data = qs.stringify({
		u: this.$route.query.u,
		});
		var config = {
		method: "post",
		url: "https://app.stonewoodfinancial.com/scripts/proposals/rmd/get-client-branding.php",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
		},
		data: data,
		};
		var self = this;
		axios(config)
		.then(function (response) {
			self.headerLogo = response.data.company_logo;
			if (response.data.company_name == "") {
			self.companyName = "Discover your Retirement Tax Bill";
			} else {
			self.companyName = response.data.company_name;
			}
			self.fullName = response.data.userFullName;
			self.phone = response.data.phone;
			self.gtm_code = response.data.gtm_code;
			self.lead_capture_disclosure = response.data.lead_capture_disclosure;
			self.$store.state.company.company_name = self.companyName;
			self.$store.state.company.userFullName = self.userFullName;
			self.$store.state.company.phone = self.phone;
			self.$store.state.company.lead_capture_disclosure =
			self.lead_capture_disclosure;
			self.$store.state.company.meeting_url =
			response.data.lead_capture_meet_url;
			self.companyWebsite = response.data.company_website;
			if (response.data.lead_allow == false) {
			self.dialog = true;
			}
			if (self.gtm_code != "") {
			(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
			new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
			j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
			'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
			})(window,document,'script','dataLayer',self.gtm_code);
			}
		})
		.catch(function (error) {
			console.log(error);
		});
	},
	methods: {
		getGTMIFrameUrl(){
		return "https://www.googletagmanager.com/ns.html?id=" + this.gtm_code
		},
		formatURL(url) {
			if (!url) return '';

			url = url.trim();

			// If the URL starts with http:// or https://, return it as-is
			if (/^https?:\/\//i.test(url)) {
				return url;
			}

			// Otherwise, add http:// as the default scheme
			try {
				const formattedUrl = 'http://' + url;
				const parsed = new URL(formattedUrl);
				return parsed.href;
			} catch (e) {
				console.error('Invalid URL:', url);
				return ''; // Return an empty string for invalid URLs
			}
		}
	},
	name: "landingHeader",
	data() {
		return {
			headerLogo: "",
			companyName : "",   
			fullName : "",
			phone: "",
			gtm_code: "",
			dialog : false, 
			companyWebsite : ""
		};
	},
};
</script>
